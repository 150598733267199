<template>
  <b-card
    title="รายชื่อสมาชิก"
    no-body
  >
    <b-card-body>
      <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
      <b-form @submit.prevent>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="เบอร์โทร / ยูส"
              label-for="mc-first-name"
            >
              <b-form-input
                id="mc-first-name"
                v-model="userInput"
                placeholder="เบอร์โทร / ยูส"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="รายการ"
              label-for="mc-city"
            >
              <b-row>
                <b-col md="6">
                  <v-select
                    v-model="tableQuery.txtype"
                    label="title"
                    :options="txtypes"
                    :reduce="option => option.value"
                  />
                </b-col>
                <b-col md="6">
                  <v-select
                    v-model="tableQuery.platform"
                    label="title"
                    :options="platformOpts"
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="วันที่รายการ"
              label-for="mc-country"
            >
              <b-row>
                <b-col md="6">
                  <flat-pickr
                    v-model="tableQuery.startdate"
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                  />
                </b-col>
                <b-col md="6">
                  <flat-pickr
                    v-model="tableQuery.enddate"
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group />
          </b-col>
          <!-- submit and reset -->
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="searchMember"
            >
              ค้นหา
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="clearSearch"
            >
              เคลียร์
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!-- </div> -->
    </b-card-body>

    <b-table
      ref="membertable"
      striped
      hover
      responsive
      class="position-relative"
      :busy.sync="isBusy"
      :per-page="tableQuery.page_size"
      :current-page="tableQuery.page"
      :items="fetchRecords"
      :fields="fields"
    >
      <template #cell(username)="data">
        {{ data.item.phoneno }}<br>
        {{ data.item.username }}
      </template>
      <template #cell(fullname)="data">
        {{ `${data.item.fname} ${data.item.lname}` }}
      </template>
      <template #cell(note)="data">
        {{ `[${data.item.platform}][${data.item.txtype}` }}] {{ `${data.item.note}` }}
      </template>
      <template #cell(amount)="data">
        <div style="text-align: right;">
          {{ `${numberWithCommas(data.item.amount)}` }}
        </div>
      </template>
      <template #cell(balance)="data">
        <div style="text-align: right;">
          {{ `${numberWithCommas(data.item.balance)}` }}
        </div>
      </template>
      <template #cell(get_promo)="data">
        <b-badge :variant="{'Y':'light-success', 'N':'light-primary'}[data.item.get_promo]">
          {{ {'Y':'รับโปร', 'N':'ไม่รับ'}[data.item.get_promo] }}
        </b-badge>
      </template>
      <template #cell(invited_by)="data">
        <b-badge v-if="!data.item.invited_by" :variant="'light-danger'">
          ไม่มี
        </b-badge>
        <div v-if="data.item.invited_by">
          {{ data.item.refer_by.phoneno }}<br>
          {{ data.item.refer_by.fname }} {{ data.item.refer_by.lname }}
        </div>
      </template>
      <template #cell(status)="data">
        <div style="text-align:center;">
          <b-badge :variant="{'Y':'light-success', 'N':'light-danger'}[data.item.is_active]">
            {{ {'Y':'แอคทีฟ', 'N':'ระงับ'}[data.item.is_active] }}
          </b-badge><br>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            :variant="{'Y':'outline-danger', 'N':'outline-success'}[data.item.is_active]"
            size="sm"
            @click="updateMemberStatus(data.item, data.item.is_active === 'Y' ? 'inactive' : 'active')"
          >
            {{ {'Y':'ระงับยูสนี้', 'N':'แอคทีฟยูสนี้'}[data.item.is_active] }}
          </b-button>
        </div>
      </template>
      <template #cell(afflink)="data">
        <div style="text-align:center;">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-info"
            size="sm"
            @click="copyReferralLink(data.item)"
          >
            <feather-icon
              icon="CopyIcon"
              class="mr-50"
            />
            ก๊อปลิ้งชวนเพื่อน
          </b-button>
        </div>
      </template>
      <template #cell(btn_credit)="data">
        <div style="text-align:center;">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-info"
            size="sm"
            @click="updateCredit(data.item)"
          >
            <feather-icon
              icon="RefreshCcwIcon"
              class="mr-50"
            />
            เครดิต
          </b-button>
        </div>
      </template>
      <template #cell(btn_detail)="data">
        <div style="text-align:center;">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-info"
            size="sm"
            @click="viewMember(data.item)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            แก้ไขข้อมูล
          </b-button>
        </div>
      </template>

    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="tableQuery.page_size"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="tableQuery.page"
          :total-rows="totalRows"
          :per-page="tableQuery.page_size"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BFormInput, BButton, BCardBody, BRow, BCol, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      pageOptions: [100, 200, 500, 1000],
      userInput: '',
      txtypes: [{ title: 'ทั้งหมด', value: 'ALL' }, { title: 'รายการฝาก', value: 'DEPOSIT' }, { title: 'รายการแจ้งถอน', value: 'WITHDRAW' }, { title: 'รายการคืนเงิน', value: 'REFUND' }, { title: 'โปรโมชั่น', value: 'PROMOTION' }, { title: 'วางเดิมพัน', value: 'BET' }, { title: 'เคลียร์บิล', value: 'BETWIN' }, { title: 'เดิมพันที่ถูกยกเลิก', value: 'BETCANCEL' }],
      platformOpts: [{ title: 'ทั้งหมด', value: '' }, { title: 'กระเป๋าเงิน', value: 'WALLET' }, { title: 'SA', value: 'SA' }, { title: 'SEXY', value: 'AE' }, { title: 'WM', value: 'WM' }, { title: 'DG', value: 'DG' }, { title: 'CQ9', value: 'CQ9' }, { title: 'JOKER', value: 'JOKER' }, { title: 'JDB', value: 'JDB' }, { title: 'MG', value: 'MG' }, { title: 'SBO', value: 'SBO' }, { title: 'HOTGRAPH', value: 'HOTGRAPH' }, { title: 'LOTTO', value: 'LOTTO' }],
      totalRows: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      tableQuery: {
        page: 1,
        page_size: 100,
        txtype: 'ALL',
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'created_at', label: 'วันที่' },
        { key: 'username', label: 'ยูส/เบอร์' },
        { key: 'note', label: 'รายละเอียด' },
        { key: 'amount', label: 'จำนวน' },
        { key: 'balance', label: 'คงเหลือ' },
        { key: 'ref2', label: 'อ้างอิง 2' },
        { key: 'ref3', label: 'อ้างอิง 3' },
        { key: 'platform', label: 'ระบบ' },
      ],
      /* eslint-disable global-require */
      items: [
      ],
      /* eslint-disable global-require */
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.totalRows = this.items.length
    if (this.$route.params.user !== null && this.$route.params.user !== undefined)
      this.tableQuery.phoneno = this.$route.params.user
  },
  methods: {
    searchMember() {
      this.$refs.membertable.refresh()
    },
    clearSearch() {
      this.tableQuery = {
        page: 1,
        page_size: 100,
      }
      this.$refs.membertable.refresh()
    },
    async fetchRecords(ctx) {
      console.log('[fetchRecords]', ctx)
      let fieldName = 'phoneno'
      delete this.tableQuery.searchBy
      if (/[a-zA-Z]/.test(this.userInput)) {
        fieldName = 'username'
        this.tableQuery.username = this.userInput
        this.tableQuery.searchBy = 'username'
        delete this.tableQuery.phoneno
      } else if (this.userInput !== '') {
        this.tableQuery.phoneno = this.userInput
        this.tableQuery.searchBy = 'phoneno'
        delete this.tableQuery.username
      }

      let query = ''
      if (this.tableQuery !== {}) {
        Object.keys(this.tableQuery).forEach((key, idx) => {
          if (idx > 0) query += '&'
          if (this.tableQuery[key].value)
            query += `${key}=${encodeURIComponent(this.tableQuery[key].value)}`
          else
            query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
        })
      }

      if (!this.tableQuery.searchBy) {
        console.log(this.tableQuery)
        this.items = []
        return this.items
      }

      try {
        const { data: _wallettx } = await this.$http.get(`wallet-transactions${query !== '' ? (`?${query}`) : ''}`)
        this.items = _wallettx.wallet_transactions.data
        this.totalRows = _wallettx.wallet_transactions.meta.total
      } catch (err) {
        this.items = []
        this.totalRows = 0
      }
      return this.items
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
